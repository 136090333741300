<template>
  <div id="indexer">
    <Portal to="upload-index-action-bar">
      <div class="row">
        <ExpandableSearch v-if="false" class="q-mr-sm" @search="searchRow" />

        <FilterItems
          v-if="false"
          item="file"
          is-bordered
          :columns="columns"
          :filter-by.sync="filterBy"
          class="q-mr-sm"
          @update="getFiles"
        />

        <BaseActionButton
          v-tooltip.top="'refresh'"
          is-flat
          icon="eva-refresh"
          class="q-mr-sm"
          @click="refreshAll"
        />
      </div>
    </Portal>

    <!-- content -->

    <ContentWrapper>
      <template #main>
        <!-- file viewer -->

        <FileViewerWrapper
          v-if="isFileViewerVisible"
          :file="selectedFiles[0]"
          :type="2"
          @back="hideFileViewer"
          @show-files="showFilesSheet"
          @delete="showDeleteConfirmation"
          @next="showNextFile"
          @previous="showPreviousFile"
          @free-hand="freeHandIndexing"
        >
          <template #header-right>
            <BaseActionButton
              v-show="showAutoProcessBtn"
              v-tooltip.top="'move to autoprocess'"
              is-flat
              icon="mdi-robot-outline"
              class="q-mr-sm"
              @click="showAutoProcessConfirmation(selectedFiles[0])"
            />

            <!-- <BaseActionButton
              v-tooltip.top="'move to manual index'"
              is-flat
              icon="mdi-label-multiple-outline"
              class="q-mr-sm"
              @click="showManualIndexConfirmation(selectedFiles[0])"
            /> -->
          </template>
        </FileViewerWrapper>

        <!-- ... -->

        <template v-else>
          <!-- files -->

          <BaseScrollbar height="calc(100vh - 219px)" class="q-px-md q-mt-md">
            <BaseLoadingBarSheet
              v-if="loadingBarContent"
              class="loading-content"
            />
            <AutoProcessingFileList
              :files="autoProcessingFiles"
              :selected-files="selectedFiles"
              @select="selectFile"
              @delete="showDeleteConfirmation"
              @manual-index="showManualIndexConfirmation"
              @selectedFile="selectedOneFile"
              @selectAll="selectAllFile"
              @getFiles="getSpecficFiles"
            />

            <IndexingFileList
              :files="indexingFiles"
              :selected-files="selectedFiles"
              :indexing-type="indexingType"
              :fields-type="fieldsType"
              @select="selectFile"
              @view="showFileViewer"
              @process="showAutoProcessConfirmation"
              @delete="showDeleteConfirmation"
              @selectedFile="selectedOneFile"
              @selectAll="selectAllFile"
              @getFiles="getSpecficFiles"
            />

            <!-- <PartiallyIndexedFileList
              :files="partiallyIndexedFiles"
              :selected-files="selectedFiles"
              @select="selectFile"
              @view="showFileViewer"
              @delete="showDeleteConfirmation"
            /> -->

            <!-- <IndexedFileList
              :files="indexedFiles"
              :selected-files="selectedFiles"
              @select="selectFile"
              @view="showFileViewer"
              @delete="showDeleteConfirmation"
              @export="showExportConfirmation"
            /> -->

            <ExportingFileList
              :files="exportingFiles"
              :selected-files="selectedFiles"
              @select="selectFile"
              @delete="showDeleteConfirmation"
              @selectedFile="selectedOneFile"
              @selectAll="selectAllFile"
              @getFiles="getSpecficFiles"
            />
          </BaseScrollbar>

          <!-- ... -->

          <!-- pagination -->

          <Pagination
            v-if="!isFileViewerVisible"
            :total-items="totalItems"
            :current-page.sync="currentPage"
            :items-per-page.sync="itemsPerPage"
            class="q-ma-md"
          />

          <!-- ... -->
        </template>
      </template>

      <template #aside>
        <RepositoryFields
          :workspace-id="workspaceId"
          :repository-id="repositoryId"
          :file="selectedFile"
          :has-select-all-files="hasSelectAllFiles"
          :selected-files="selectedFiles"
          :old-selected-id="oldSelectedId"
          :old-selected-item-id="oldSelectedItemId"
          :refreshed="isRefreshed"
          @refresh="getFiles"
          @updteSavedFile="updteSavedFile"
          @upload="queueFilesForUpload"
        />
      </template>
    </ContentWrapper>

    <!-- ... -->

    <!-- file sheet -->

    <FileSheet
      v-model="isFilesSheetVisible"
      :file-list="fileList"
      :selected-files="selectedFiles"
      @select="singleSelectFile"
    />

    <!-- ... -->

    <!-- delete confirmation -->

    <ConfirmDeleteFiles
      v-model="isDeleteConfirmationVisible"
      :files="selectedFiles"
      @delete="deleteFiles"
    />

    <!-- ... -->

    <!-- manual index confirmation -->

    <ConfirmManualIndexFiles
      v-model="isManualIndexConfirmationVisible"
      :files="selectedFiles"
      @update="setStatus"
    />

    <!-- ... -->

    <!-- export confirmation -->

    <ConfirmExportFiles
      v-model="isExportConfirmationVisible"
      :files="selectedFiles"
      @export="exportFiles"
    />

    <!-- ... -->

    <!-- auto process confirmation -->

    <ConfirmAutoProcessFiles
      v-model="isAutoProcessConfirmationVisible"
      :repository-id="repositoryId"
      :files="selectedFiles"
      :ocr-pages.sync="ocrPages"
      :ocr-type.sync="ocrType"
      :ocr-pattern.sync="ocrPattern"
      @update="setStatus"
    />

    <!-- ... -->
  </div>
</template>

<script>
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";
import FilterItems from "@/components/common/display/item-actions/components/FilterItems.vue";
import ContentWrapper from "@/views/upload-and-index/components/ContentWrapper.vue";
import AutoProcessingFileList from "./components/AutoProcessingFileList.vue";
// import NotIndexedFileList from "./components/NotIndexedFileList.vue";
// import PartiallyIndexedFileList from "./components/PartiallyIndexedFileList.vue";
// import IndexedFileList from "./components/IndexedFileList.vue";
import IndexingFileList from "./components/IndexingFileList.vue";
import ExportingFileList from "./components/ExportingFileList.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import FileViewerWrapper from "@/views/upload-and-index/components/FileViewerWrapper.vue";
import ConfirmDeleteFiles from "@/views/upload-and-index/components/ConfirmDeleteFiles.vue";
import ConfirmManualIndexFiles from "@/views/upload-and-index/components/ConfirmManualIndexFiles.vue";
import ConfirmExportFiles from "./components/ConfirmExportFiles.vue";
import FileSheet from "@/views/upload-and-index/components/FileSheet.vue";
import RepositoryFields from "./components/repository-fields/RepositoryFields.vue";
import ConfirmAutoProcessFiles from "@/views/upload-and-index/components/ConfirmAutoProcessFiles.vue";
import { uploadAndIndex, repository } from "@/api/factory.js";
import { startCase, capitalize } from "lodash-es";

export default {
  name: "Indexer",

  components: {
    Portal,
    ExpandableSearch,
    FilterItems,
    ContentWrapper,
    AutoProcessingFileList,
    //NotIndexedFileList,
    // PartiallyIndexedFileList,
    //IndexedFileList,
    IndexingFileList,
    ExportingFileList,
    Pagination,
    FileViewerWrapper,
    ConfirmDeleteFiles,
    ConfirmManualIndexFiles,
    ConfirmExportFiles,
    FileSheet,
    RepositoryFields,
    ConfirmAutoProcessFiles,
  },

  props: {
    workspaceId: {
      type: Number,
      required: true,
    },

    repositoryId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "repository",
          label: "Repository",
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "uploadedFrom",
          label: "Uploaded From",
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "lastModifiedBy",
          label: "Last Modified By",
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "lastModifiedAt",
          label: "Last Modified At",
          dataType: DataType.DATE_TIME,
        },
      ],
      filterBy: [],
      files: [],
      fileData: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      selectedFiles: [],
      isFileViewerVisible: false,
      isFilesSheetVisible: false,
      isDeleteConfirmationVisible: false,
      isAutoProcessConfirmationVisible: false,
      isManualIndexConfirmationVisible: false,
      isExportConfirmationVisible: false,
      ocrPages: [1], //[]
      ocrType: "",
      ocrPattern: false,
      hasSelectAllFiles: false,
      indexingType: "",
      fieldsType: "",
      oldSelectedId: 0,
      getSavedFile: false,
      getSavedFileId: 0,
      oldSelectedItemId: 0,
      isRefreshed: false,
      watchFreeHandValues: null,
      loadingBarContent: false,
    };
  },

  computed: {
    autoProcessingFiles() {
      return this.files.filter((file) => file.status === "AUTO_PROCESSING");
    },

    indexingFiles() {
      return this.files.filter(
        (file) => file.status !== "AUTO_PROCESSING" && file.status !== "EXPORT"
      );
    },

    notIndexedFiles() {
      return this.files.filter((file) => file.status === "NOT_INDEXED");
    },

    partiallyIndexedFiles() {
      return this.files.filter((file) => file.status === "PARTIALLY_INDEXED");
    },

    indexedFiles() {
      return this.files.filter((file) => file.status === "INDEXED");
    },

    exportingFiles() {
      return this.files.filter((file) => file.status === "EXPORT");
    },

    selectedFile() {
      if (this.selectedFiles.length !== 1) {
        return {};
      }

      if (
        ["AUTO_PROCESSING", "EXPORT"].includes(this.selectedFiles[0].status)
      ) {
        return {};
      }

      return this.selectedFiles[0];
    },

    fileList() {
      return [
        {
          status: "indexing",
          files: this.indexingFiles,
        },
        // {
        //   status: "partially indexed",
        //   files: this.partiallyIndexedFiles,
        // },
        // {
        //   status: "indexed",
        //   files: this.indexedFiles,
        // },
      ];
    },

    getFileTypes() {
      const groupedFileTypes = this.selectedFiles.reduce((acc, cur) => {
        const fileType = this.getFileType(cur.name);
        acc[fileType] = acc[fileType] + 1 || 1;
        return acc;
      }, {});

      return Object.keys(groupedFileTypes);
    },

    showAutoProcessBtn() {
      if (
        this.selectedFiles.length === 0 ||
        this.indexingType === "MANUAL" ||
        this.fieldsType === "STATIC"
      ) {
        return false;
      }

      const fileTypes = this.getFileTypes;
      return fileTypes.every((type) =>
        ["pdf", "tif", "tiff", "png", "jpeg", "jpg"].includes(type)
      );
    },
  },

  watch: {
    repositoryId: {
      immediate: true,
      handler() {
        if (this.repositoryId) {
          this.getRepository();
        }
      },
    },

    selectedFiles: {
      deep: true,
      handler() {
        if (this.selectedFiles.length > 1) {
          this.hasSelectAllFiles = true;
        } else {
          this.hasSelectAllFiles = false;
        }
      },
    },
  },

  mounted() {
    this.$watch(
      (vm) => [vm.currentPage, vm.itemsPerPage],
      () => this.getFiles(),
      { immediate: true, deep: true }
    );
  },

  methods: {
    selectFile(file) {
      const fileIdx = this.selectedFiles.findIndex(
        (_file) => _file.id === file.id
      );

      const isFileAlreadySelected = fileIdx > -1;

      isFileAlreadySelected
        ? this.selectedFiles.splice(fileIdx, 1)
        : this.selectedFiles.push(file);
    },

    singleSelectFile(file) {
      this.selectedFiles = [file];
    },

    showFileViewer(file) {
      //if (file.uploadedFrom === "Web") {
      this.selectedFiles = [file];
      let indexingValues = {
        name: file.repository.value,
        fields: file.fields,
      };
      localStorage.setItem("repositoryDetails", JSON.stringify(indexingValues));
      this.isFileViewerVisible = true;
      //}
    },

    hideFileViewer() {
      this.isFileViewerVisible = false;
      this.selectedFiles = [];
      clearInterval(this.watchFreeHandValues);
      localStorage.removeItem("freeHand");
      localStorage.removeItem("repositoryDetails");
    },

    showFilesSheet() {
      this.isFilesSheetVisible = true;
    },

    showDeleteConfirmation(file) {
      if (file) {
        this.singleSelectFile(file);
      }
      this.isDeleteConfirmationVisible = true;
    },

    showManualIndexConfirmation(file) {
      if (file) {
        this.singleSelectFile(file);
      }
      this.isManualIndexConfirmationVisible = true;
    },

    showExportConfirmation(file) {
      if (file) {
        this.singleSelectFile(file);
      }
      this.isExportConfirmationVisible = true;
    },

    showPreviousFile() {
      const idx = this.uploadedFiles.findIndex(
        (file) => file.id === this.selectedFiles[0].id
      );

      if (idx - 1 >= 0) {
        this.selectedFiles = [this.uploadedFiles[idx - 1]];
      }
    },

    showNextFile() {
      const idx = this.uploadedFiles.findIndex(
        (file) => file.id === this.selectedFiles[0].id
      );

      if (idx + 1 < this.uploadedFiles.length) {
        this.selectedFiles = [this.uploadedFiles[idx + 1]];
      }
    },

    getFileType(fileName) {
      return fileName.split(".").reverse()[0].slice(0, 4).toLowerCase();
    },

    searchRow(search) {
      if (search) {
        this.files = this.fileData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.files = this.fileData;
      }
    },

    showAutoProcessConfirmation(file) {
      if (file) {
        this.singleSelectFile(file);
      }
      //this.isAutoProcessConfirmationVisible = true;
      this.setStatus("AUTO_PROCESSING");
    },

    freeHandIndexing(file) {
      console.log(file);
      localStorage.setItem("freeHand", "true");
      localStorage.setItem("freeHandClicked", "true");

      this.watchFreeHandValues = setInterval(
        () => this.checkFreeHandValue(),
        1000
      );
    },

    checkFreeHandValue() {
      if (localStorage.getItem("freeHand")) {
        if (localStorage.getItem("freeHand") === "true") {
          console.log("read values");
          localStorage.setItem("freeHand", "false");
          let indexingValues = JSON.parse(
            localStorage.getItem("repositoryDetails")
          );
          if (indexingValues) {
            this.selectedFiles[0].fields = indexingValues.fields;
          }
        }
      }
    },

    /* api Functions */

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.fieldsType === "STATIC") {
        this.ocrType = "STANDARD";
      } else {
        this.ocrType = "ADVANCED";
      }
      this.indexingType = payload.indexingType;
      this.fieldsType = payload.fieldsType;
    },
    updteSavedFile() {
      this.getSavedFile = true;
      this.getFiles();
      this.getSavedFile = false;
    },

    async getFiles() {
      this.loadingBarContent = true;

      if (this.getSavedFile) {
        this.getSavedFile = false;
        //this.$store.commit("showLoadingBar");

        const { error, payload } = await uploadAndIndex.getIndexFiles({
          repositoryId: this.repositoryId,
          mode: "BROWSE",
          sortBy: { criteria: "g.createdAt", order: "DESC" },
          groupBy: "",
          filterBy: [
            {
              filters: [
                {
                  criteria: "status",
                  condition: "IS_NOT_EQUALS_TO",
                  value: "UPLOADED",
                },
                {
                  criteria: "g.id",
                  condition: "IS_EQUALS_TO",
                  value: this.oldSelectedId,
                },
              ],
            },
          ],
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
        });

        //this.$store.commit("hideLoadingBar");
        this.loadingBarContent = false;
        if (error) {
          this.$alert.error(error);
          return;
        }

        const { meta, data } = payload;
        const updatedFileData = data[0].value[0];
        this.files = this.files.map((file) => {
          if (file.id === this.oldSelectedId) {
            return {
              ...file,
              name: updatedFileData.name,
              status: updatedFileData.status,
              fields: updatedFileData.fields,
              createdAt: updatedFileData.createdAt,
              createdBy: updatedFileData.createdBy,
              totalPages: updatedFileData.totalPages,
              ocrType: updatedFileData.ocrType,
              suggestions: updatedFileData.suggestions
                ? JSON.parse(updatedFileData.suggestions)
                : {},
              uploadedFrom: startCase(capitalize(updatedFileData.uploadedFrom)),
              prediction: updatedFileData.prediction,
            };
          }
          return file;
        });
        this.fileData = this.files;
        this.totalItems = meta.totalItems;
        this.isFileViewerVisible = false;
        this.isFilesSheetVisible = false;
      } else {
        this.files = [];
        //this.$store.commit("showLoadingBar");
        this.files = [];
        this.selectedFiles = [];

        const { error, payload } = await uploadAndIndex.getIndexFiles({
          repositoryId: this.repositoryId,
          mode: "BROWSE",
          sortBy: {
            criteria: "g.createdAt",
            order: "DESC",
          },
          groupBy: "",
          filterBy: [
            {
              filters: [
                {
                  criteria: "status",
                  condition: "IS_NOT_EQUALS_TO",
                  value: "UPLOADED",
                },
              ],
            },
          ],
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
        });

        //this.$store.commit("hideLoadingBar");
        this.loadingBarContent = false;
        if (error) {
          this.$alert.error(error);
          return;
        }

        const { meta, data } = payload;

        this.files = data[0].value.map((file) => ({
          id: file.id,
          name: file.name,
          status: file.status,
          size: 0,
          isVerified: false,
          hasError: false,
          fields: file.fields,
          itemId: file.itemId,
          repository: file.repository,
          createdAt: file.createdAt,
          createdBy: file.createdBy,
          totalPages: file.totalPage,
          ocrType: file.ocrType,
          suggestions: file.suggestions ? JSON.parse(file.suggestions) : {},
          uploadedFrom: startCase(capitalize(file.uploadedFrom)),
          prediction: file.prediction,
        }));
        this.fileData = this.files;
        this.totalItems = meta.totalItems;
        this.isFileViewerVisible = false;
        this.isFilesSheetVisible = false;
      }
      this.isRefreshed = false;
    },

    async setStatus(status) {
      this.$store.commit("showLoadingBar");

      let ocrTypeIndexing = this.ocrType;

      let origin = location.origin;
      if (origin === "https://trial.ezofis.com") {
        ocrTypeIndexing = "ADVANCED";
      }

      const payload = this.selectedFiles.map((file) => ({
        id: file.id,
        status,
        OCRPages: this.ocrPages,
        OCRType: ocrTypeIndexing,
        OCRTool: this.ocrType === "STANDARD" ? "LEADTOOLS" : "AZURE",
        OCRPattern: this.ocrPattern,
      }));
      // console.log(payload);
      const { error } = await uploadAndIndex.indexSetStatus(payload);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      // this.$alert.success(
      //   `${this.selectedFiles.length} ${
      //     this.selectedFiles.length > 1 ? "files" : "file"
      //   } saved successfully`
      // );

      this.getFiles();
    },

    async deleteFiles() {
      this.$store.commit("showLoadingBar");

      const payload = this.selectedFiles.map((file) => file.id);
      const { error } = await uploadAndIndex.deleteIndexedFiles(payload);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      // this.$alert.success(
      //   `${this.selectedFiles.length} ${
      //     this.selectedFiles.length > 1 ? "files" : "file"
      //   } deleted successfully`
      // );

      this.getFiles();
    },

    async exportFiles(status) {
      this.$store.commit("showLoadingBar");
      const file = this.selectedFiles[0];
      const { error } = await uploadAndIndex.updateFile(file.id, {
        repositoryId: this.repositoryId,
        itemId: file.itemId,
        status: status,
        fields: file.fields.map((field) => ({
          name: field.name,
          value: field.value,
          type: field.dataType,
        })),
      });
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      const name = file.name;
      if (status === "EXPORT") {
        this.$alert.success(`File "${name}" Exported`);
      }
      // else {
      //   this.$alert.info(`File "${name}" ${this.status}`);
      // }
      this.getFiles();
    },

    selectedOneFile(file) {
      if (this.selectedFiles.length > 0) {
        this.oldSelectedId = this.selectedFiles[0].id;
        this.oldSelectedItemId = this.selectedFiles[0].itemId;
      }
      const fileIdx = this.selectedFiles.findIndex(
        (_file) => _file.id === file.id
      );
      const isFileAlreadySelected = fileIdx > -1;

      this.selectedFiles = [];
      if (!isFileAlreadySelected) {
        this.selectedFiles.push(file);
      }
    },

    selectAllFile(file, type) {
      this.hasSelectAllFiles = file;
      if (file) {
        if (type === "INDEXING") {
          this.selectedFiles = this.files.filter(
            (file) =>
              file.status !== "AUTO_PROCESSING" && file.status !== "EXPORT"
          );
        } else if (type === "AUTO_PROCESSING") {
          this.selectedFiles = this.files.filter(
            (file) => file.status === "AUTO_PROCESSING"
          );
        } else if (type === "EXPORT") {
          this.selectedFiles = this.files.filter(
            (file) => file.status === "EXPORT"
          );
        }
      } else {
        this.selectedFiles = [];
      }
    },

    queueFilesForUpload(files) {
      this.$emit("upload", files);
    },

    async getSpecficFiles(type) {
      let value = "NOT_INDEXED";
      switch (type) {
        case "AUTO_PROCESSING":
          value = "AUTO_PROCESSING";
          await this.getFilesSpecficFiles(value);
          break;
        case "INDEXING":
          value = "INDEXING";
          await this.getFilesSpecficFiles(value);
          break;
        case "EXPORT":
          value = "EXPORT";
          await this.getFilesSpecficFiles(value);
          break;
        default:
          console.log("no value present");
      }
    },
    async getFilesSpecficFiles(value) {
      const { error, payload } = await uploadAndIndex.getIndexFiles({
        repositoryId: this.repositoryId,
        mode: "BROWSE",
        sortBy: { criteria: "g.createdAt", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters:
              value !== "INDEXING"
                ? [
                    {
                      criteria: "status",
                      condition: "IS_NOT_EQUALS_TO",
                      value: "UPLOADED",
                    },
                    {
                      criteria: "status",
                      condition: "IS_EQUALS_TO",
                      value: value,
                    },
                  ]
                : [
                    {
                      criteria: "status",
                      condition: "IS_NOT_EQUALS_TO",
                      value: "UPLOADED",
                    },
                    {
                      criteria: "status",
                      condition: "IS_NOT_EQUALS_TO",
                      value: "AUTO_PROCESSING",
                    },

                    {
                      criteria: "status",
                      condition: "IS_NOT_EQUALS_TO",
                      value: "EXPORT",
                    },
                  ],
          },
        ],
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      const { data } = payload;
      if (!data.length) {
        let result;

        if (value !== "INDEXING") {
          result = this.files.filter((item) => item.status !== value);
        } else {
          const Statuses = ["NOT_INDEXED", "INDEXED", "PARTIALLY_INDEXED"];
          result = this.files.filter((item) => !Statuses.includes(item.status));
        }

        this.files = result;
      } else {
        let updatedFileData = data[0].value;

        if (
          Array.isArray(this.selectedFiles) &&
          this.selectedFiles.length > 0
        ) {
          updatedFileData = updatedFileData.filter(
            (item) => item.id !== this.selectedFiles[0].id
          );
        }
        const updateFileData = (existingFiles, updatedFiles) => {
          const existingFilesMap = new Map();
          existingFiles.forEach((file) => {
            existingFilesMap.set(file.id, file);
          });

          updatedFiles.forEach((updatedFile) => {
            if (existingFilesMap.has(updatedFile.id)) {
              this.$set(
                existingFiles,
                existingFiles.indexOf(existingFilesMap.get(updatedFile.id)),
                updatedFile
              );
            } else {
              existingFiles.unshift(updatedFile);
            }
          });
          return existingFiles;
        };
        this.files = updateFileData([...this.files], updatedFileData);
      }
    },

    async refreshAll() {
      this.isRefreshed = true;
      await this.getFiles();
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
.loading-content {
  background-color: var(--body-bg-color) !important;
  position: absolute !important;
  z-index: 9999;
  top: 0px;
}
</style>

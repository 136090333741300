<template>
  <div id="repository-fields">
    <BaseScrollbar height="calc(100vh - 192px)">
      <WorkspaceList v-model="workspaceId" />
      <RepositoryList
        v-model="repositoryId"
        :workspace-id="workspaceId"
        @input="handleRepository"
      />

      <template v-if="hasFile">
        <!-- fields -->

        <div v-if="fields.length && fieldType === 'STATIC'" class="q-pa-md">
          <ValidationObserver ref="form">
            <template v-for="(field, index) in fields">
              <template v-if="field.dataType !== 'AUTO_GENERATED'">
                <div :key="field.id" class="field-wrapper">
                  <template v-if="field.dataType === 'TABLE'">
                    <TableField
                      v-model="fieldsModel[field.name]"
                      :field="field"
                    />
                  </template>

                  <template v-else>
                    <!-- @input="getAutoFilled" -->
                    <RepositoryField
                      v-if="fieldType === 'STATIC'"
                      v-model="fieldsModel[field.name]"
                      :field="field"
                      :sync-field="syncField"
                      :repository-id="repositoryId"
                      class="col"
                      :filter="field.filter"
                      :row-index="index"
                      :parent-control="checkParentControl(field)"
                      :freezed-data="freezedData"
                      :show-freeze="true"
                      :expiry="isExpired(field)"
                      @saveSync="getSyncDataList"
                      @filterBy="filterChildControlValues"
                      @input="updateMandatory($event, field, index)"
                      @update:freezedData="updateFreezedData"
                      @update:freezed="updateFreezeState"
                      @update:removeFreezed="removeFreezedData"
                    />
                    <BotRecommendations
                      v-if="
                        fieldType === 'STATIC' &&
                        Object.keys(file.suggestions).length
                      "
                      v-model="fieldsModel[field.name]"
                      :field="field"
                      class="bot-recommendations"
                      :suggestions="
                        Array.isArray(file.suggestions)
                          ? file.suggestions
                          : file.suggestions[field.name]
                      "
                      @click="controlFocus(fieldsModel[field.name])"
                    />
                  </template>
                </div>
              </template>
            </template>
          </ValidationObserver>
        </div>

        <div v-if="fieldType === 'DYNAMIC'" class="q-pa-md">
          <div class="action-bar q-mb-sm">
            <template v-if="dynamicData.length">
              <Tabs v-model="tab" :tabs="tabs" />
            </template>
            <template v-else>
              <Tabs v-model="tab" :tabs="tabsFields" />
            </template>
          </div>
          <template v-if="tab === 'FIELDS'">
            <ValidationObserver ref="form">
              <div class="row items-center q-mb-sm">
                <div class="col q-mr-sm">
                  <div @click="addField">
                    <q-chip
                      outline
                      color="secondary"
                      class="cursor-pointer secondary-hover"
                      style="float: right"
                    >
                      <q-avatar
                        icon="mdi-plus-thick"
                        class="q-mr-sm"
                        color="secondary"
                        text-color="white"
                      />
                      Add Field
                    </q-chip>
                  </div>
                </div>
                <div class="field-label col-auto">
                  <div class="label" style="float: right">Mark All</div>
                </div>
                <BaseIcon
                  :name="
                    fieldCheckAll
                      ? 'eva-checkmark-circle-2'
                      : 'eva-radio-button-off-outline'
                  "
                  :color="fieldCheckAll ? 'secondary' : 'grey'"
                  class="q-ml-sm check-box cursor-pointer col-auto"
                  @click="fieldSelectAll()"
                />
              </div>
              <div
                v-for="(field, index) in dynamicFields"
                :key="field.id"
                class="field-wrapper"
              >
                <template>
                  <RepositoryDynamicField
                    v-model="dynamicFieldsModel[field.name]"
                    :field="field"
                    :fields="dynamicFields"
                    :repository-id="repositoryId"
                    :label-name="field.name"
                    class="col"
                  />
                  <TextField v-if="false" :value="field.name" />

                  <BaseActionButton
                    v-if="field.type === 'DYNAMIC'"
                    v-tooltip.top="'Change Type'"
                    is-flat
                    no-border
                    icon="eva-layers-outline"
                    style="margin: 36px 0px 0px 3px"
                  >
                    <q-menu
                      anchor="bottom right"
                      self="top right"
                      :offset="[0, 8]"
                    >
                      <div class="" style="width: 280px">
                        <div class="title-3 border q-pa-xs">
                          <input
                            ref="searchQuery"
                            v-model="searchType"
                            type="text"
                            class="q-pa-md"
                            autofocus
                            placeholder="Search Type"
                          />
                        </div>

                        <BaseScrollbar height="202px" class="q-px-xs q-mb-sm">
                          <div class="chips">
                            <OptionsPicker
                              :value="field.dataType"
                              :options="_dataTypes"
                              @select="changeDataType($event, index)"
                            />
                          </div>
                        </BaseScrollbar>
                      </div>
                    </q-menu>
                  </BaseActionButton>

                  <BaseActionButton
                    v-if="field.type === 'DYNAMIC'"
                    v-tooltip.top="'delete'"
                    is-flat
                    no-border
                    color="negative"
                    icon="eva-trash-outline"
                    style="margin: 36px 0px 0px 2px"
                    @click="deleteDynamicField(index)"
                  />

                  <BaseIcon
                    :name="
                      field.selected
                        ? 'eva-checkmark-circle-2'
                        : 'eva-radio-button-off-outline'
                    "
                    :color="field.selected ? 'secondary' : 'grey'"
                    class="q-mr-sm check-box cursor-pointer"
                    @click="fieldSelected(field, index)"
                  />
                </template>
              </div>
            </ValidationObserver>
          </template>
          <template v-if="tab === 'TABLE' && dynamicData.length">
            <div id="dynamicIndexingTables">
              <div class="row q-mb-sm q-mt-sm">
                <BaseActionButton
                  icon="eva-external-link-outline"
                  class="col-auto"
                  color="secondary"
                  is-flat
                  @click="expandTableModal = true"
                ></BaseActionButton>
                <!-- <div class="col">
                  <q-tabs
                    v-model="tableTab"
                    inline-label
                    outside-arrows
                    class="tableTabs"
                  >
                    <q-tab
                      v-for="(table, index) in dynamicData"
                      :key="index"
                      :name="++index"
                      :label="'Table ' + index"
                    ></q-tab>
                  </q-tabs>
                </div> -->
                <div class="field-label col q-mt-sm">
                  <div class="q-mb-sm label" style="float: right">Mark All</div>
                </div>
                <BaseIcon
                  :name="
                    tableCheckAll
                      ? 'eva-checkmark-circle-2'
                      : 'eva-radio-button-off-outline'
                  "
                  :color="tableCheckAll ? 'secondary' : 'grey'"
                  class="q-ml-sm check-box cursor-pointer col-auto q-mt-sm"
                  @click="tableSelectAll()"
                />
              </div>
              <template v-for="(table, index) in dynamicData">
                <div :key="index" class="row">
                  <div class="field-label col">
                    <div class="row items-center">
                      <div class="col-auto">
                        <template v-if="!table.showEditTableName">
                          <div @click="editTableName(table, index)">
                            <div v-if="table.tablename" class="q-mb-sm label">
                              {{ table.tablename }}
                            </div>
                            <div v-else class="q-mb-sm label">
                              Table {{ ++index }}
                            </div>
                          </div>
                        </template>

                        <input
                          v-if="table.showEditTableName"
                          ref="searchInput"
                          v-model="table.tablename"
                          type="text"
                          autocomplete="off"
                          :autofocus="table.showEditTableName"
                          class="label q-mb-sm"
                          @input="updateTableLabel(table, index)"
                          @mouseleave="validateTableLabel(table)"
                        />
                        <FormFieldError
                          v-if="table.labelError"
                          :error="table.labelError"
                          class="q-mb-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <BaseIcon
                    :name="
                      table.selected
                        ? 'eva-checkmark-circle-2'
                        : 'eva-radio-button-off-outline'
                    "
                    :color="table.selected ? 'secondary' : 'grey'"
                    class="q-ml-sm check-box cursor-pointer col-auto"
                    @click="fieldSelected(table)"
                  />
                </div>
                <table :key="table.tablename">
                  <tr v-for="(row, idx) in table.cellvalues" :key="idx">
                    <th
                      :is="cell.kind === 'NoHeader' ? 'td' : 'th'"
                      v-for="cell in row"
                      :key="`r${idx}_c${cell.columnindex}`"
                    >
                      {{ cell.cellvalue }}
                    </th>
                  </tr>
                </table>
              </template>
            </div>
          </template>
        </div>

        <!-- ... -->
      </template>

      <div v-if="exportAll" id="export-all" class="q-pa-md">
        <div
          v-if="allFiles"
          class="row items-center justify-center"
          style="height: 100%"
        >
          <div class="col-12 text-center">
            <div class="indexed">
              <BaseIcon
                name="mdi-check-circle-outline"
                size="44px"
                color="green"
              />
            </div>
            <div class="title-2 q-mt-sm">Success!</div>
            <div class="label">
              {{ indexedFiles.length }} files exported successfully
            </div>
          </div>
        </div>

        <div
          v-if="!allFiles"
          class="row items-center justify-center q-mt-md"
          style="height: 100%"
        >
          <div class="col-12 text-center">
            <div class="not-indexed">
              <BaseIcon
                name="mdi-alert-circle-outline"
                size="44px"
                inherit-color
              />
            </div>
            <div class="label">
              <div v-if="indexedFiles.length" class="indexed">
                Exported : {{ indexedFiles.length }}
              </div>
              <div class="not-indexed">
                Unprocessed : {{ notIndexedFiles.length }}
              </div>
              <div>
                Please check the mandatory fields in the unprocessed files
              </div>
            </div>
          </div>
        </div>

        <Dropzone
          :show-workspace-repository="false"
          :tab-indexing="true"
          :workspace-id="workspaceId"
          :repository-id="repositoryId"
          @upload="queueFilesForUpload"
        />
      </div>
    </BaseScrollbar>

    <!-- footer -->

    <div class="footer">
      <!-- v-if="file.status && file.status !== 'INDEXED'" -->
      <BaseButton
        v-if="file.status && fieldType === 'STATIC'"
        icon="eva-save-outline"
        label="save"
        color="secondary"
        class="q-mr-sm"
        :is-loading="isSaveLoading"
        :disabled="isExportLoading"
        @click="
          {
            isSaveLoading = true;
            saveFields('');
          }
        "
      />
      <BaseButton
        v-if="
          file.status &&
          fieldType === 'DYNAMIC' &&
          (file.status === 'EXTRACTED' || file.status === 'INDEXED')
        "
        icon="eva-save-outline"
        label="save"
        color="secondary"
        class="q-mr-sm"
        :is-loading="isSaveLoading"
        :disabled="isExportLoading"
        @click="saveDynamicFields('')"
      />
      <!-- v-if="file.status === 'INDEXED'" -->
      <BaseButton
        v-if="file.status && fieldType === 'DYNAMIC' && dynamicFields.length"
        icon="eva-external-link-outline"
        label="export"
        :is-loading="isExportLoading"
        :disabled="isSaveLoading"
        @click="
          {
            isExportLoading = true;
            saveFields('EXPORT');
          }
        "
      />

      <BaseButton
        v-if="file.status && fieldType === 'STATIC'"
        icon="eva-external-link-outline"
        label="export"
        :is-loading="isExportLoading"
        :disabled="isSaveLoading"
        @click="
          {
            isExportLoading = true;
            saveFields('EXPORT');
          }
        "
      />

      <BaseButton
        v-if="hasSelectAllFiles"
        icon="eva-external-link-outline"
        label="export all"
        :is-loading="isExportLoading"
        @click="exportAllFiles()"
      />
    </div>

    <!-- ... -->

    <Modal
      v-model="expandTableModal"
      width="60vw"
      height="80vh"
      @input="expandTableModal = false"
    >
      <!-- title -->

      <template #title>Link Tables</template>

      <!-- ... -->

      <template #default>
        <div id="dynamicIndexingTablesExpand">
          <div v-for="(table, index) in dynamicData" :key="index">
            <div class="row">
              <div class="field-label col">
                <div class="row items-center">
                  <div class="col-auto">
                    <template v-if="!table.showEditTableName">
                      <div @click="editTableName(table, index)">
                        <div v-if="table.tablename" class="q-mb-sm label">
                          {{ table.tablename }}
                        </div>
                        <div v-else class="q-mb-sm label">
                          Table {{ ++index }}
                        </div>
                      </div>
                    </template>
                    <input
                      v-if="table.showEditTableName"
                      ref="searchInput"
                      v-model="table.tablename"
                      type="text"
                      autocomplete="off"
                      :autofocus="table.showEditTableName"
                      class="label q-mb-sm"
                      @input="updateTableLabel(table, index)"
                      @mouseleave="validateTableLabel(table)"
                    />
                    <FormFieldError
                      v-if="table.labelError"
                      :error="table.labelError"
                      class="q-mb-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
            <table>
              <tr v-for="(row, idx) in table.cellvalues" :key="idx">
                <th
                  :is="cell.kind === 'NoHeader' ? 'td' : 'th'"
                  v-for="cell in row"
                  :key="`r${idx}_c${cell.columnindex}`"
                >
                  {{ cell.cellvalue }}
                </th>
              </tr>
            </table>
          </div>
        </div>
      </template>
    </Modal>

    <Confirm
      v-model="showNoFieldsSelectModal"
      icon="mdi-file-alert"
      icon-color="red"
      @input="
        {
          isExportLoading = false;
          showNoFieldsSelectModal = false;
        }
      "
    >
      <template #title>{{ file.name }}</template>

      <template #default>
        <div class="q-py-md text-md">
          <p v-if="hasNoFieldSelect">
            Do you want to export this file without selecting any field

            <span v-if="hasNoTableSelect"> and table </span>?
          </p>

          <p v-if="!hasNoFieldSelect && hasNoTableSelect">
            Do you want to export this file without selecting table?
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          @click="
            {
              isExportLoading = false;
              showNoFieldsSelectModal = false;
            }
          "
        />

        <BaseButton
          label="yes"
          color="primary"
          @click="saveFields('EXPORT', 'save')"
        />
      </template>
    </Confirm>
  </div>
</template>

<script>
import WorkspaceList from "@/views/upload-and-index/components/WorkspaceList.vue";
import RepositoryList from "@/views/upload-and-index/components/RepositoryList.vue";
import { ValidationObserver } from "vee-validate";
import TableField from "./components/table-field/TableField.vue";
import RepositoryField from "./components/RepositoryField.vue";
import BotRecommendations from "./components/BotRecommendations.vue";
import { repository, uploadAndIndex, connector } from "@/api/factory.js";
import { isEmpty } from "lodash-es";
import RepositoryDynamicField from "./components/RepositoryDynamicField.vue";
import Tabs from "@/components/common/tabs/Tabs";
import Modal from "@/components/common/popup/Modal.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import { ValidationProvider } from "vee-validate";
import Confirm from "@/components/common/popup/Confirm.vue";
import OptionsPicker from "@/components/common/form/select-field/OptionsPicker";
import OrDivider from "@/components/common/OrDivider.vue";
import Dropzone from "../../../uploader/components/Dropzone.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "RepositoryFields",

  components: {
    WorkspaceList,
    RepositoryList,
    ValidationObserver,
    TableField,
    RepositoryField,
    BotRecommendations,
    RepositoryDynamicField,
    Tabs,
    Modal,
    TextField,
    ValidationProvider,
    Confirm,
    OptionsPicker,
    OrDivider,
    Dropzone,
    FormFieldError,
  },

  props: {
    workspaceId: {
      type: Number,
      required: true,
    },

    repositoryId: {
      type: Number,
      required: true,
    },

    file: {
      type: Object,
      required: true,
    },

    hasSelectAllFiles: {
      type: Boolean,
      default: false,
    },

    selectedFiles: {
      type: Array,
      default: () => [],
    },
    oldSelectedItemId: {
      type: Number,
      default: 0,
    },
    oldSelectedId: {
      type: Number,
      default: 0,
    },
    refreshed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fields: [],
      fieldsModel: {},
      fieldType: "",
      repositoryFields: [],
      dynamicFieldsModel: {},
      dynamicFields: [],
      tab: "FIELDS",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Fields",
          value: "FIELDS",
        },
        {
          id: this.$nano.id(),
          label: "Table",
          value: "TABLE",
        },
      ],
      tabsFields: [
        {
          id: this.$nano.id(),
          label: "Fields",
          value: "FIELDS",
        },
      ],
      dynamicData: [],
      fieldCheckAll: false,
      tableCheckAll: false,
      tableTab: 0,
      tableIndex: 0,
      expandTableModal: false,
      showEditTableName: false,
      hoverTableLabel: false,
      labelError: "",
      syncMapping: {},
      syncField: {},
      showNoFieldsSelectModal: false,
      hasNoFieldSelect: false,
      hasNoTableSelect: false,
      dataTypes: [
        {
          id: this.$nano.id(),
          label: "Short Text",
          value: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          label: "Number",
          value: "NUMBER",
        },
        {
          id: this.$nano.id(),
          label: "Date",
          value: "DATE",
        },
        {
          id: this.$nano.id(),
          label: "Time",
          value: "TIME",
        },
        {
          id: this.$nano.id(),
          label: "Date Time",
          value: "DATE_TIME",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Single Select",
        //   value: "SINGLE_SELECT",
        // },
      ],

      searchType: "",
      repositoryFieldsData: [],
      indexedFiles: [],
      notIndexedFiles: [],
      exportAll: false,
      allFiles: false,
      dynamicFileId: 0,
      advancedOCRResultId: 0,
      isSaveLoading: false,
      isExportLoading: false,
      freezedData: {},
      isFreezed: false,
      fileChangeSave: false,
      valueChanged: false,
    };
  },

  computed: {
    hasFile() {
      return !isEmpty(this.file);
    },

    fileSizeLimit() {
      if (!this.session.license) {
        return 52428800;
      }

      return 52428800;
    },

    status() {
      let mandatoryFieldCounter = 0;
      let mandatoryFieldValueCounter = 0;
      let optionalFieldCounter = 0;
      let optionalFieldValueCounter = 0;

      this.fields.forEach((field) => {
        if (field.dataType !== "AUTO_GENERATED") {
          field.isMandatory ? mandatoryFieldCounter++ : optionalFieldCounter++;

          if (this.fieldsModel[field.name]) {
            field.isMandatory
              ? mandatoryFieldValueCounter++
              : optionalFieldValueCounter++;
          }
        }
      });

      if (mandatoryFieldCounter === mandatoryFieldValueCounter) {
        return "INDEXED";
      }

      if (mandatoryFieldValueCounter + optionalFieldValueCounter) {
        return "PARTIALLY_INDEXED";
      }

      return "NOT_INDEXED";
    },

    _dataTypes() {
      if (this.searchType) {
        return this.dataTypes.filter((type) =>
          type.label.toLowerCase().includes(this.searchType.toLowerCase())
        );
      }
      return this.dataTypes;
    },
  },

  watch: {
    oldSelectedId: {
      immediate: true,
      deep: true,
      async handler(newId, oldId) {
        if (
          newId !== 0 &&
          (this.valueChanged || Object.keys(this.freezedData).length > 0)
        ) {
          console.log(newId, oldId);
          this.fileChangeSave = true;
          await this.saveFields();
          this.valueChanged = false;
        }
      },
    },
    file: {
      immediate: true,
      deep: true,
      async handler() {
        if (!this.hasFile) {
          return;
        }
        this.handleRepository(this.file.repository.id);
      },
    },

    dynamicFields: {
      deep: true,
      async handler() {
        if (this.dynamicFields) {
          // if (this.dynamicFields.length) {
          //   let fields = this.dynamicFields.reduce((acc, element, index) => {
          //     let index1 = acc.findIndex((item) => item.name === element.name);
          //     // console.log(index1);
          //     if (index1 >= 0) {
          //       acc[index1].isDuplicate = true;
          //       this.dynamicFields[index].isDuplicate = true;
          //     }
          //     acc.push(element);

          //     return acc;
          //   }, []);
          //   console.log(fields);

          //   this.dynamicFields = fields;
          //   let isDuplicateArray = fields.filter((item) => item.isDuplicate);
          //   console.log(isDuplicateArray);
          //   if (isDuplicateArray.length == 0) {
          //     this.processDynamicFieldsModel(this.dynamicFields);
          //   }
          // }
          this.processDynamicFieldsModel(this.dynamicFields);
        }
      },
    },

    fieldsModel: {
      immediate: true,
      deep: true,
      handler() {
        for (let key in this.fieldsModel) {
          if (this.fieldsModel[key]) {
            let field = this.fields.find((item) => item.name === key);
            this.updateMandatory(this.fieldsModel[key], field);
          }
        }
      },
    },

    selectedFiles: {
      deep: true,
      handler() {
        if (this.selectedFiles.length) {
          this.indexedFiles = [];
          this.notIndexedFiles = [];
          this.exportAll = false;
          this.allFiles = false;
        }
      },
    },

    refreshed: {
      deep: true,
      handler() {
        if (this.refreshed) {
          this.freezedData = {};
        }
      },
    },
  },

  created() {
    this.getHubLink();
  },

  methods: {
    controlFocus(field) {
      localStorage.setItem("findText", field);
    },

    removeFreezedData(feildToRemove) {
      if (feildToRemove in this.freezedData) {
        delete this.freezedData[feildToRemove];
      }
    },

    updateFreezedData(newfreezedData) {
      this.freezedData = {
        ...this.freezedData,
        ...newfreezedData,
      };
    },

    updateFreezeState(newFreezeState) {
      this.isFreezed = newFreezeState;
    },

    async handleRepository(value) {
      this.repositoryId = value;
      await this.getRepositoryFields();
      if (this.fieldType === "STATIC") {
        const fields = await this.getStaticFields();
        if (fields) {
          this.processFieldsModel(fields);
          this.sortFields(fields);
        }
      } else {
        const fields = await this.getDynamicFields();
        if (fields) {
          this.processDynamicFieldsModel(fields);
        }
        // this.sortDynamicFields(fields);
      }
    },

    processFieldsModel(fields) {
      this.fieldsModel = {};

      fields.forEach((field) => {
        this.$set(this.fieldsModel, field.name, "");
      });

      if (this.file.fields) {
        this.file.fields.forEach((field) => {
          if (field.type === "TABLE") {
            if (field.value) {
              this.fieldsModel[field.name] = JSON.parse(field.value);
            } else {
              this.fieldsModel[field.name] = [];
            }
          } else {
            this.fieldsModel[field.name] = field.value;
          }
        });
      }

      if (this.freezedData && this.fieldsModel) {
        Object.keys(this.freezedData).forEach((key) => {
          if (key in this.fieldsModel) {
            this.fieldsModel[key] = this.freezedData[key];
          }
        });
      }
    },

    sortFields(fields) {
      const folderFields = fields.filter((field) => field.level > 0);
      folderFields.sort((field1, field2) => field1.level - field2.level);
      const mandatoryFields = fields.filter(
        (field) => field.level === 0 && field.isMandatory
      );
      const optionalFields = fields.filter((field) => !field.isMandatory);
      this.fields = [...folderFields, ...mandatoryFields, ...optionalFields];
    },

    computeStatus() {
      let mandatoryFieldCounter = 0;
      let mandatoryFieldValueCounter = 0;
      let optionalFieldCounter = 0;
      let optionalFieldValueCounter = 0;

      this.fields.forEach((field) => {
        field.isMandatory ? mandatoryFieldCounter++ : optionalFieldCounter++;

        if (this.fieldsModel[field.name]) {
          field.isMandatory
            ? mandatoryFieldValueCounter++
            : optionalFieldValueCounter++;
        }
      });

      if (mandatoryFieldCounter === mandatoryFieldValueCounter) {
        this.status = "INDEXED";
        return;
      }

      if (mandatoryFieldValueCounter + optionalFieldValueCounter) {
        this.status = "PARTIALLY_INDEXED";
        return;
      }

      this.status = "NOT_INDEXED";
    },

    getStaticFields() {
      return this.repositoryFields.fields.map((field) => ({
        ...field,
        options: JSON.parse(field.options),
      }));
    },

    processDynamicFieldsModel(fields) {
      this.dynamicFieldsModel = {};

      fields.forEach((field, index) => {
        this.dynamicFields[index].value = field.value;
        this.$set(
          this.dynamicFieldsModel,
          field.name,
          field.value ? field.value : ""
        );
      });

      // if (this.file.fields) {
      //   this.file.fields.forEach((field) => {
      //     this.dynamicFieldsModel[field.name] = field.value;
      //   });
      // }
    },

    fieldSelected(field, index) {
      if (index > -1) {
        this.dynamicFields[index].value = this.dynamicFieldsModel[field.name];
      }
      field.selected = !field.selected;
    },

    fieldSelectAll() {
      this.fieldCheckAll = !this.fieldCheckAll;
      this.dynamicFields = this.dynamicFields.map((field) => ({
        ...field,
        value: this.dynamicFieldsModel[field.name],
        selected: this.fieldCheckAll,
      }));
    },

    tableSelectAll() {
      this.tableCheckAll = !this.tableCheckAll;
      this.dynamicData = this.dynamicData.map((table) => ({
        ...table,
        selected: this.tableCheckAll,
        showEditTableName: false,
      }));
    },

    addField() {
      let length = this.dynamicFields.filter((item) => item.type === "DYNAMIC");
      for (let key in this.dynamicFieldsModel) {
        let index = this.dynamicFields.findIndex((item) => {
          return item.name === key;
        });
        if (index > -1) {
          if (this.dynamicFieldsModel[key]) {
            this.dynamicFields[index].value = this.dynamicFieldsModel[key];
          }
        }
      }

      this.dynamicFields.unshift({
        dataType: "SHORT_TEXT",
        id: this.$nano.id(),
        name: `Label ${length.length + 1}`,
        selected: true,
        value: "",
        type: "DYNAMIC",
      });
      this.processDynamicFieldsModel(this.dynamicFields);
    },

    changeDataType(dataType, index) {
      this.dynamicFields[index].dataType = dataType;
    },

    deleteDynamicField(index) {
      this.dynamicFields.splice(index, 1);
    },

    checkParentControl(control) {
      let parentFieldFound = false;
      this.fields.forEach((field) => {
        if (field.options.masterFormParentColumn) {
          if (control.name === field.options.masterFormParentColumn) {
            parentFieldFound = true;
            return;
          }
        }
      });

      return parentFieldFound;
    },

    filterChildControlValues(field) {
      this.fields.forEach((rowField) => {
        if (rowField.options.masterFormParentColumn) {
          if (field.name === rowField.options.masterFormParentColumn) {
            rowField.filterBy = [
              {
                criteria: field.options.masterTableColumn,
                condition: "IS_EQUALS_TO",
                value: this.fieldsModel[field.name],
                dataType: "",
              },
            ];
            //rowField.filter = rowField.filter ? !rowField.filter : true;
            rowField.filter = rowField.filter + 1;
          }
        }
      });
    },

    updateMandatory(value, field) {
      this.valueChanged = true;
      if (value) {
        let settings = "";
        if (field.options.mandatorySettings) {
          settings = field.options.mandatorySettings.find(
            (item) => item.value === value
          );
          // console.log(settings);
        }
        if (settings) {
          for (let i = 0; i < this.fields.length; i++) {
            let find = settings.fields.find(
              (item) => item === this.fields[i].name
            );
            // console.log(find);
            if (find) {
              this.fields[i].isMandatory = true;
            } else {
              this.fields[i].isMandatory =
                this.repositoryFields.fields[i].isMandatory;
            }
          }
        } else {
          let fields1 = this.repositoryFields.fields;

          let result = [];

          let filter1 = this.fields.filter(
            ({ isMandatory: id1 }) =>
              !fields1.some(({ isMandatory: id2 }) => id2 === id1)
          );

          let filter2 = fields1.filter(
            ({ isMandatory: id1 }) =>
              !this.fields.some(({ isMandatory: id2 }) => id2 === id1)
          );

          if (filter1.length) {
            result = filter1;
          } else if (filter2.length) {
            result = filter2;
          }

          // console.log(results);
          result.forEach((item) => {
            let index = this.fields.findIndex((file) => {
              return file.name === item.name;
            });
            if (index >= 0) {
              this.fields[index].isMandatory = !this.fields[index].isMandatory;
            }
          });
        }
      } else {
        let fields1 = this.repositoryFields.fields;

        let result = [];

        let filter1 = this.fields.filter(
          ({ isMandatory: id1 }) =>
            !fields1.some(({ isMandatory: id2 }) => id2 === id1)
        );

        let filter2 = fields1.filter(
          ({ isMandatory: id1 }) =>
            !this.fields.some(({ isMandatory: id2 }) => id2 === id1)
        );

        if (filter1.length) {
          result = filter1;
        } else if (filter2.length) {
          result = filter2;
        }

        result.forEach((item) => {
          let index = this.fields.findIndex((file) => {
            return file.name === item.name;
          });
          if (index >= 0) {
            this.fields[index].isMandatory = !this.fields[index].isMandatory;
          }
        });
      }
    },

    handleChange(e) {
      // console.log(e);
      const files = e.target.files;
      const validFiles = [];
      const invalidFileTypes = ["exe"];

      if (!files.length) {
        return;
      }

      for (let file of files) {
        if (file.size > this.fileSizeLimit) {
          this.$alert.warning(`${file.name} - Maximum file size exceeded`);
          return;
        }

        const fileType = file.name.split(".").reverse()[0];
        if (invalidFileTypes.includes(fileType)) {
          this.$alert.warning(`${file.name} - Invalid file type`);
          return;
        }

        validFiles.push({
          id: this.$nano.id(),
          name: file.name,
          type: fileType.slice(0, 4),
          size: file.size,
          createdAt: "",
          status: "UPLOADING",
          file: file,
          uploadedPercentage: 0,
        });
      }

      this.$refs.dropzone.value = "";
      this.$emit("upload", validFiles);
    },

    queueFilesForUpload(files) {
      this.$emit("upload", files);
    },

    /* api Functions */

    async getRepositoryFields() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      //console.log(payload);

      this.fieldType = payload.fieldsType;
      this.repositoryFieldsData = payload;
      this.repositoryFields = payload;
    },

    async getDynamicFields() {
      let fields = [];
      this.dynamicFields = [];
      this.dynamicData = [];
      this.$store.commit("showLoadingBar");

      let input = {
        filterBy: [
          {
            filters: [
              {
                criteria: "fileId",
                condition: "IS_EQUALS_TO",
                value: this.file.id,
              },
            ],
          },
        ],
      };

      const { error, payload } = await uploadAndIndex.getOCRData(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      // let data = [
      //   {
      //     fields: [
      //       {
      //         key: "AmountDue",
      //         value: "49,049.14",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "CustomerAddress",
      //         value:
      //           "POST BAG NO.4 AND 18, VALLUR HIGH ROAD, AVADI CHENNAI TIRUVALLUR 33 CHENNAI 600054 TAMIL NADU IN",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "CustomerAddressRecipient",
      //         value: "TUBE INVESTMENTS OF INDIA LIMITED",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "CustomerId",
      //         value: "DUTYINMAA",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "CustomerName",
      //         value: "TUBE INVESTMENTS OF INDIA LIMITED",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "CustomerTaxId",
      //         value: "33AADCT1398N1ZX",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "DueDate",
      //         value: "20-Dec-2021",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "InvoiceDate",
      //         value: "20-Dec-2021",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "InvoiceId",
      //         value: "MAA340320",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "InvoiceTotal",
      //         value: "49,049.14",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "Items",
      //         value: null,
      //         boundingValues: null,
      //       },
      //       {
      //         key: "PaymentTerm",
      //         value: "0 Days",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "PurchaseOrder",
      //         value: "748848",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "RemittanceAddressRecipient",
      //         value: "DHL EXPRESS (INDIA) PVT. LTD.",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "SubTotal",
      //         value: "44,377.00",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "TaxDetails",
      //         value: null,
      //         boundingValues: null,
      //       },
      //       {
      //         key: "VendorAddress",
      //         value:
      //           "Plot No:12-B,South Phase,3rd cross street Guindy Industrial Estate Guindy Chennai Tamil Nadu 600032 INDIA",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "VendorAddressRecipient",
      //         value: "DHL Express (India) Pvt Ltd",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "VendorName",
      //         value: "DHL Express (India) Pvt Ltd",
      //         boundingValues: null,
      //       },
      //       {
      //         key: "VendorTaxId",
      //         value: "33AABCD3611Q1ZP",
      //         boundingValues: null,
      //       },
      //     ],
      //     tables: [
      //       {
      //         tablename: "table0",
      //         cellvalues: [
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "Origin",
      //             rowindex: 0,
      //             columnindex: 0,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "KIX",
      //             rowindex: 0,
      //             columnindex: 1,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "Pieces",
      //             rowindex: 0,
      //             columnindex: 2,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "1",
      //             rowindex: 0,
      //             columnindex: 3,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "Contents CUT OFF M/C PARTS - TOP KNIFE - T",
      //             rowindex: 0,
      //             columnindex: 4,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "Destination",
      //             rowindex: 1,
      //             columnindex: 0,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "MAA",
      //             rowindex: 1,
      //             columnindex: 1,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "Weight",
      //             rowindex: 1,
      //             columnindex: 2,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "2.24",
      //             rowindex: 1,
      //             columnindex: 3,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "Assessed Value 49,049.14 Arrival Date",
      //             rowindex: 1,
      //             columnindex: 4,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "18-Dec-2021",
      //             rowindex: 1,
      //             columnindex: 7,
      //             boundingValues: null,
      //           },
      //         ],
      //       },
      //       {
      //         tablename: "table1",
      //         cellvalues: [
      //           {
      //             kind: "columnHeader",
      //             cellvalue: "Billing Details",
      //             rowindex: 0,
      //             columnindex: 0,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "columnHeader",
      //             cellvalue: "",
      //             rowindex: 1,
      //             columnindex: 0,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "columnHeader",
      //             cellvalue: "",
      //             rowindex: 1,
      //             columnindex: 1,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "columnHeader",
      //             cellvalue: "Description",
      //             rowindex: 1,
      //             columnindex: 3,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "columnHeader",
      //             cellvalue: "Amount",
      //             rowindex: 1,
      //             columnindex: 4,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "GSTIN",
      //             rowindex: 2,
      //             columnindex: 0,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "33AADCT1398N1ZX",
      //             rowindex: 2,
      //             columnindex: 1,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "CUSTOMS DUTY (REIMBURSEMENT OF COST)",
      //             rowindex: 2,
      //             columnindex: 3,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "11,999.00",
      //             rowindex: 2,
      //             columnindex: 4,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "Place of Supply",
      //             rowindex: 3,
      //             columnindex: 0,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "33, TAMIL NADU",
      //             rowindex: 3,
      //             columnindex: 1,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue:
      //               "IGST (REIMBURSEMENT OF COST) S W SURCHARGE(REIMBURSEMENT OF COST)",
      //             rowindex: 3,
      //             columnindex: 3,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "31,177.00 1,201.00",
      //             rowindex: 3,
      //             columnindex: 4,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "",
      //             rowindex: 4,
      //             columnindex: 0,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "",
      //             rowindex: 4,
      //             columnindex: 1,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "996719-WAREHOUSE CHARGES",
      //             rowindex: 4,
      //             columnindex: 3,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "100.00",
      //             rowindex: 4,
      //             columnindex: 4,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "Total Non-Taxable Total Taxable",
      //             rowindex: 5,
      //             columnindex: 0,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "44,377.00 3,959.44",
      //             rowindex: 5,
      //             columnindex: 1,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue:
      //               "996719-FORMAL CLEARANCE CHARGES 996719-DISBURSEMENT FEE CGST @ 9% SGST@ 9%",
      //             rowindex: 5,
      //             columnindex: 3,
      //             boundingValues: null,
      //           },
      //           {
      //             kind: "NoHeader",
      //             cellvalue: "2,750.00 1,109.44 356.35 356.35",
      //             rowindex: 5,
      //             columnindex: 4,
      //             boundingValues: null,
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ];

      if (payload.length) {
        let data = JSON.parse(payload[0].resultJson);
        this.dynamicFileId = payload[0].fileId;
        this.advancedOCRResultId = payload[0].id;
        if (data) {
          if (data[0].fields) {
            data[0].fields.forEach((field, index) => {
              fields.push({
                dataType: "SHORT_TEXT",
                name: field.key,
                value: field.value,
                id: index + 1,
                selected: field.selected ? field.selected : false,
              });
            });
            this.dynamicFields = fields;
            this.dynamicFields = fields.map((field) => ({
              ...field,
            }));
          }

          if (data[0].tables) {
            let tableData = [];
            data[0].tables.forEach((dat) => {
              let rows = new Array();
              let rowIndex = 0;
              rows[rowIndex] = new Array();
              dat.cellvalues.forEach((row) => {
                if (rowIndex === row.rowindex) {
                  rows[rowIndex].push(row);
                } else {
                  rowIndex = row.rowindex;
                  rows[rowIndex] = new Array();
                  rows[rowIndex].push(row);
                }
              });
              tableData.push({
                tablename: dat.tablename,
                cellvalues: rows,
                showEditTableName: false,
                selected: dat.selected ? dat.selected : false,
              });
            });

            this.dynamicData = tableData;
          }
        }
      }

      return fields;
    },

    async saveFields(indexStatus, notCheck) {
      if (indexStatus && this.fieldType === "STATIC") {
        const isValid = await this.$refs.form.validate();
        if (!isValid) {
          this.isSaveLoading = false;
          this.isExportLoading = false;
          return;
        }
      }

      if (!indexStatus) {
        indexStatus = this.status;
      }

      let inputData = {};

      if (this.fieldType === "STATIC") {
        inputData = {
          repositoryId: this.repositoryId,
          itemId: this.fileChangeSave
            ? this.oldSelectedItemId
            : this.file.itemId,
          status: indexStatus,
          fields: this.fields.map((field) => ({
            name: field.name,
            value:
              field.dataType === "TABLE"
                ? JSON.stringify(this.fieldsModel[field.name])
                : this.fieldsModel[field.name],
            type: field.dataType,
          })),
        };
      } else {
        this.showNoFieldsSelectModal = false;
        if (!notCheck && indexStatus === "EXPORT") {
          let hasSelectField = this.dynamicFields.find((item) => item.selected);
          var hasSelectTable = "";
          if (this.dynamicData.length) {
            hasSelectTable = this.dynamicData.find((item) => item.selected);
          }
          if (!hasSelectField || !hasSelectTable) {
            this.hasNoFieldSelect = !hasSelectField ? true : false;
            this.hasNoTableSelect =
              !hasSelectTable || this.dynamicData.length === 0 ? true : false;
            this.showNoFieldsSelectModal = true;
            return;
          }
        }
        let dynamicSelectedFields = [];
        this.dynamicFields.filter((field) => {
          if (field.selected) {
            dynamicSelectedFields.push({
              name: field.name,
              value: this.dynamicFieldsModel[field.name],
              type: field.dataType,
            });
          }
        });

        if (this.dynamicData.length) {
          this.dynamicData.forEach((table) => {
            if (table.selected) {
              dynamicSelectedFields.push({
                // name: `Link Table${++index}`,
                name: table.tablename,
                value: JSON.stringify(table),
                type: "TABLE",
              });
            }
          });
        }

        if (dynamicSelectedFields.length) {
          let fields = dynamicSelectedFields.reduce((acc, element, index) => {
            let index1 = acc.findIndex((item) => item.name === element.name);
            // console.log(index1);
            if (index1 >= 0) {
              acc[index1].isDuplicate = true;
              dynamicSelectedFields[index].isDuplicate = true;
            }
            acc.push(element);

            return acc;
          }, []);
          let isDuplicateArray = fields.filter((item) => item.isDuplicate);
          if (isDuplicateArray.length) {
            this.$alert.warning(
              "More than one same field names found: " +
                isDuplicateArray[0].name
            );
            return;
          }
        }

        inputData = {
          repositoryId: this.repositoryId,
          itemId: this.file.itemId,
          status: indexStatus,
          ocrResult: JSON.stringify(dynamicSelectedFields),
        };
      }

      // console.log(inputData);
      // console.log(JSON.stringify(inputData));
      this.$store.commit("showLoadingBar");

      const { error } = await uploadAndIndex.updateFile(
        this.fileChangeSave ? this.oldSelectedId : this.file.id,
        inputData
      );

      this.$store.commit("hideLoadingBar");
      if (this.fileChangeSave) {
        this.$emit("updteSavedFile", this.oldSelectedId);
      }
      if (error) {
        this.$alert.error(error);
        return;
      }

      this.fieldCheckAll = false;
      this.tableCheckAll = false;
      const name = this.file.name;
      if (!this.fileChangeSave) {
        if (indexStatus === "INDEXED") {
          this.$alert.success(`File "${name}" ${this.status}`);
        } else if (indexStatus === "EXPORT") {
          this.$alert.success(`File "${name}" Exported`);
        } else {
          this.$alert.info(`File "${name}" ${this.status}`);
        }
        this.isSaveLoading = false;
        this.isExportLoading = false;
        this.$emit("refresh");
      }
      this.fileChangeSave = false;
    },

    updateTableLabel(table, index1) {
      table.labelError = "";
      let labelFound = false;

      this.dynamicData.forEach((item, index) => {
        if (item.tablename === table.tablename && index !== index1) {
          labelFound = true;
          return;
        }
      });
      if (labelFound) {
        table.labelError = "Specified label already assigned";
      }
      if (!table.tablename) {
        table.labelError = "Label Should not be empty";
      }
    },

    async getHubLink() {
      const { error, payload } = await connector.getHubLink({
        filterBy: [],
        mode: "BROWSE",
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      let result = payload.filter(
        (item) =>
          item.createdBy === this.$store.state.session.id &&
          item.repositoryId === Number(this.repositoryId)
      );
      if (result.length) {
        this.syncField = JSON.parse(result[0].payloadMapping);
        this.syncMapping = result[0];
      }
    },

    async getSyncDataList(field) {
      let input = {};
      for (let key in this.syncField) {
        this.$set(input, key, this.fieldsModel[field.name]);
      }
      const { error, payload } = await connector.getSyncValue({
        hublinkId: this.syncMapping.id,
        payloadMapping: JSON.stringify(input),
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.data[0].value.length) {
        let modal = payload.data[0].value[0];
        for (let key in modal) {
          if (modal[key]) {
            this.fieldsModel[key] = modal[key];
          }
        }
      }
    },

    async exportAllFiles() {
      this.isExportLoading = true;
      let input = [];

      this.selectedFiles.forEach((file) => {
        if (file.status === "INDEXED") {
          input.push({
            id: file.id,
            status: "EXPORT",
          });
        }
      });
      if (input.length) {
        const { error, payload } = await uploadAndIndex.indexSetStatus(input);
        if (error) {
          this.$alert.error(error);
          return;
        }
        // console.log(payload);
        if (payload) {
          this.exportAll = true;
        }
        this.indexedFiles = this.selectedFiles.filter(
          (file) => file.status === "INDEXED"
        );
        this.notIndexedFiles = this.selectedFiles.filter(
          (file) => file.status !== "INDEXED"
        );
        this.allFiles = this.selectedFiles.every(
          (file) => file.status === "INDEXED"
        );
        this.$emit("refresh");
      } else {
        this.$alert.warning(`Which is no files in the indexed status`);
      }
      this.isExportLoading = false;
    },

    async saveDynamicFields(indexStatus, notCheck) {
      this.isSaveLoading = true;
      if (!indexStatus) {
        indexStatus = this.status;
      }
      let inputData = {};

      this.showNoFieldsSelectModal = false;
      if (!notCheck && indexStatus === "EXPORT") {
        let hasSelectField = this.dynamicFields.find((item) => item.selected);
        var hasSelectTable = "";
        if (this.dynamicData.length) {
          hasSelectTable = this.dynamicData.find((item) => item.selected);
        }
        if (!hasSelectField || !hasSelectTable) {
          this.hasNoFieldSelect = !hasSelectField ? true : false;
          this.hasNoTableSelect = !hasSelectTable ? true : false;
          this.showNoFieldsSelectModal = true;
          return;
        }
      }
      let dynamicSelectedFields = [
        {
          fields: [],
          tables: [],
        },
      ];

      this.dynamicFields.forEach((field) => {
        dynamicSelectedFields[0].fields.push({
          key: field.name,
          value: this.dynamicFieldsModel[field.name],
          selected: field.selected,
        });
      });

      if (this.dynamicData.length) {
        this.dynamicData.forEach((table) => {
          let cellvalues = [];
          table.cellvalues.forEach((cell) => {
            cellvalues.push(...cell);
          });
          dynamicSelectedFields[0].tables.push({
            cellvalues: cellvalues,
            columncount: table.cellvalues[table.cellvalues.length - 1].length,
            rowcount: table.cellvalues.length,
            tablename: table.tablename,
            selected: table.selected,
          });
          // console.log(cellvalues);
        });
      }

      if (dynamicSelectedFields.length) {
        if (
          dynamicSelectedFields[0].fields.length ||
          dynamicSelectedFields[0].tables.length
        ) {
          let fields = dynamicSelectedFields.reduce((acc, element, index) => {
            let index1 = acc.findIndex((item) => item.name === element.name);
            // console.log(index1);
            if (index1 >= 0) {
              acc[index1].isDuplicate = true;
              dynamicSelectedFields[index].isDuplicate = true;
            }
            acc.push(element);
            return acc;
          }, []);
          let isDuplicateArray = fields.filter((item) => item.isDuplicate);
          if (isDuplicateArray.length) {
            this.$alert.warning(
              "More than one same field names found: " +
                isDuplicateArray[0].name
            );
            this.isSaveLoading = false;
            return;
          }
        } else {
          this.$alert.warning("Choose any one of fields");
          this.isSaveLoading = false;
          return;
        }
      } else {
        this.$alert.warning("Choose any one of fields");
        this.isSaveLoading = false;
        return;
      }

      inputData = {
        fileId: this.dynamicFileId,
        resultJson: JSON.stringify(dynamicSelectedFields),
      };

      // console.log(inputData);
      // console.log(JSON.stringify(inputData));
      this.$store.commit("showLoadingBar");

      const { error } = await uploadAndIndex.saveDynamicFields(
        this.advancedOCRResultId,
        inputData
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.fieldCheckAll = false;
      this.tableCheckAll = false;

      const name = this.file.name;

      this.$alert.success(`File "${name}" Indexed`);
      this.isSaveLoading = false;
      await this.setStatusInDynamicFiles();
    },

    async setStatusInDynamicFiles() {
      let input = [];

      input.push({
        id: this.dynamicFileId,
        status: "INDEXED",
      });

      const { error, payload } = await uploadAndIndex.indexSetStatus(input);
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
      if (payload) {
        // this.exportAll = true;
      }
      this.$emit("refresh");
    },

    async validateTableLabel(table) {
      if (!table.tablename) {
        this.labelError = "Label should not be empty";
        return;
      } else if (table.labelError) {
        return;
      } else {
        table.labelError = "";
        table.showEditTableName = false;
      }
    },

    editTableName(table) {
      table.showEditTableName = true;
    },

    isExpired(item) {
      if (this.selectedFiles && this.selectedFiles[0].prediction) {
        let selectedFile;

        try {
          selectedFile = JSON.parse(this.selectedFiles[0].prediction);
        } catch (error) {
          console.log("Error parsing prediction field:", error);
          return false;
        }

        if (item.dataType === "DATE") {
          const today = new Date().toISOString().split("T")[0];

          if (Array.isArray(selectedFile)) {
            let isExpiredField = selectedFile.some((field) => {
              if (!field.value || isNaN(Date.parse(field.value))) {
                console.error("Invalid date value:", field.value);
                return false;
              }

              const fieldDate = new Date(field.value);
              const fieldDateStr = fieldDate.toISOString().split("T")[0];
              return (
                field.name === item.name &&
                field.type.trim() === "DATE" &&
                fieldDateStr < today &&
                field.prediction === "Expired"
              );
            });

            return isExpiredField;
          } else {
            console.log("Parsed prediction is not an array.");
            return false;
          }
        } else {
          console.log("Item is not of type DATE");
          return false;
        }
      } else {
        console.log("Selected files are empty or not defined");
        return false;
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#repository-fields {
  .field-wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;

    .bot-recommendations {
      margin: 30px 0px 0px 8px;
    }

    .check-box {
      margin: 45px 0px 0px 8px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
  }

  #dynamicIndexingTables {
    table {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      margin-bottom: 15px;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }
    }
  }

  .q-tab {
    min-height: 0px !important;
  }

  .tableTabs {
    width: 160px;
    margin: auto;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .hint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.q-menu .border {
  border: 1px solid var(--divider-color) !important;
}

#modal #dynamicIndexingTablesExpand {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
      white-space: pre-line;
    }
  }
}

#export-all {
  // display: flex;
  // align-items: center;

  .indexed {
    color: var(--green);
  }

  .not-indexed {
    color: var(--orange);
  }
}

.label {
  color: var(--icon-color);
  font-weight: 500;
  text-transform: capitalize;
  font-size: 13px;
  // line-height: 1.25rem;
}
</style>

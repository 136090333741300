import { render, staticRenderFns } from "./RepositoryFields.vue?vue&type=template&id=40a268eb&scoped=true&"
import script from "./RepositoryFields.vue?vue&type=script&lang=js&"
export * from "./RepositoryFields.vue?vue&type=script&lang=js&"
import style0 from "./RepositoryFields.vue?vue&type=style&index=0&id=40a268eb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a268eb",
  null
  
)

export default component.exports
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QChip,QAvatar,QMenu,QTabs,QTab});
